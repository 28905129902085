﻿.CourseContent {
    border: solid 2px #eee;
    padding: 1rem 1rem 0.2rem 1rem;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    @include clearfix;

    @media print {
        display: none !important;
    }

    em {
        font-style: italic;
    }

    strong, b {
        font-weight: bold;
        color: #24435D;
    }

    blockquote {
        margin: 0 3em 0.8em 3em;
        padding: 0;
    }

    p {
    }

    table {
        margin: 0 0 0.8em 0;
        padding: 0;
        border-collapse: collapse;
        background-color: #ECF1EF;
        clear: both;

        td, th {
            padding: 4px;
            vertical-align: top;
            border: solid 2px #fff;
        }

        th {
            text-align: center;
            font-weight: bold;
        }
    }


    h1 {
        color: #000099;
        font-size: 2rem;
        font-weight: bold;
        line-height: 2rem;
        text-align: left;
        clear: both;

        &:first-child {
            @extend .mt-0;
        }
    }

    h2 {
        color: #CC0000;
        font-size: 1.75rem;
        font-weight: bold;
        @extend .mt-3;
        @extend .mb-2;
        padding: 0 0 2px 0;
        text-align: left;
        width: auto;
        border-bottom: solid 2px #36648B;
        clear: both;

        &:first-child {
            @extend .mt-0;
        }
    }

    h3 {
        color: #CC0000;
        font-size: 1.5rem;
        font-weight: bold;
        @extend .mt-3;
        @extend .mb-2;
        padding: 0 0 2px 0;
        text-align: left;
        width: auto;
        border-bottom: solid 2px #36648B;
        clear: both;

        &:first-child {
            @extend .mt-0;
        }
    }

    > div > h1, > div > h2, > div > h3 {
        margin-top: 0;
    }

    h4 {
        font-size: 1rem;
        font-weight: bold;
        @extend .mt-3;
        @extend .mb-2;
        padding: 0 0 2px 0;
        text-align: left;

        &:first-child {
            @extend .mt-0;
        }
    }

    img {
        max-width: 75%;
        height: auto;

        &.left {
            display: block;
            @extend .float-start;
            margin: 0 0.5rem 0.5rem 0;
        }

        &.right {
            display: block;
            @extend .float-end;
            margin: 0 0 0.5rem 0.5rem;
        }
    }
    /* mobile adjustments */
    @include media-breakpoint-down(md) {
        img, img.left, img.right {
            display: block !important;
            float: none !important;
            margin: 0.5rem 0 !important;
        }

        border-left: none;
        border-right: none;
        padding: 1em 0 0.2em 0;
    }
}
