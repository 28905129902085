﻿.readalong {
    @extend .bg-secondary;
    @extend .py-1;
    @extend .px-2;
    color: #fff;

    $bkgactive: #008B45;
    $bkgtrial: #1874CD;
    $bkginactive: #EEE8CD;

    .readalong-left {
        @include media-breakpoint-up(sm) {
            height: 68px;
        }

        .status {
            border-radius: 12px;
            border-color: #ccc;
            color: #000;
            padding: 6px;

            .subhead {
                @extend .text-center;
            }

            .statusname {
                @extend .mt-1;
                @extend .text-center;
                font-weight: bold;
            }

            background: #fff;

            &.active {
                background: -moz-linear-gradient(top, #fff 50%, $bkgactive 50%);
                background: -webkit-linear-gradient(top, #fff 50%, $bkgactive 50%);
                background: linear-gradient(to bottom, #fff 50%, $bkgactive 50%);

                .statusname {
                    color: #fff;
                }
            }

            &.inactive {
                background: -moz-linear-gradient(top, #fff 50%, $bkginactive 50%);
                background: -webkit-linear-gradient(top, #fff 50%, $bkginactive 50%);
                background: linear-gradient(to bottom, #fff 50%, $bkginactive 50%);
            }

            &.trial {
                background: -moz-linear-gradient(top, #fff 50%, $bkgtrial 50%);
                background: -webkit-linear-gradient(top, #fff 50%, $bkgtrial 50%);
                background: linear-gradient(to bottom, #fff 50%, $bkgtrial 50%);

                .statusname {
                    color: #fff;
                }
            }
        }

        .features {
            @extend .text-center;
            @extend .text-lg-start;
            line-height: 1.3;

            .new-feature {
                color: #ee6055;
                font-weight: bold;
                animation-name: trialcolorpalette;
                animation-duration: 5s;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                animation-timing-function: linear;
            }

            a {
                @extend .text-decoration-underline;
                @extend .text-info;
            }
        }
    }

    .readalong-right {
        .audiocontainer {
            width: 100%;
            height: 32px;
            position: relative;
            @extend .mt-1;
        }

        audio {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
        }

        .trialwarning {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 2;
            background: rgba(255,255,255,0.80);

            .trialwarning-inside {
                margin-top: 4px;
                color: #000;
                text-align: center;

                .inactive {
                    font-weight: bold;
                    color: #DC143C;
                }
            }
        }

        .playercaption {
            font-size: 0.8rem;
            @extend .d-none;
            @extend .d-sm-block;
            @extend .mt-2;
            @extend .mt-lg-0;
            @extend .text-lg-end;
            @extend .text-center;
            @extend .mb-1;
            @extend .mb-lg-2;
        }
    }

    @keyframes trialcolorpalette {
        0% {
            color: #ee6055;
        }

        25% {
            color: #60d394;
        }

        50% {
            color: #aaf683;
        }

        75% {
            color: #ffd97d;
        }

        100% {
            color: #ff9b85;
        }
    }
}
