﻿/* styles for validation helpers */
.field-validation-error {
    color: #b94a48;
    @extend .form-text;
}

.field-validation-valid {
    display: none;
}

input.input-validation-error {
    border: 1px solid #b94a48;
}

input[type="checkbox"].input-validation-error {
    border: 0 none;
}

.validation-summary-errors {
    color: #b94a48;
}

.validation-summary-valid {
    display: none;
}

/* Header */
#top-header-name {
    text-align: right;
}

.top-banner {
    @extend .py-3;

    h1, h2 {
        @extend .my-0;
    }
}


/** Course summary **/
.coursesummary {
    border-collapse: collapse;
    @extend .my-3;
    width: 100%;
    border: solid 1px #ccc;

    .statushead {
        width: 80px;
        @extend .p-1;
        text-align: center;
        background-color: #F4F4F4;
        font-weight: bold;
        border-bottom: solid 1px #ccc;
    }

    .titlehead {
        @extend .p-1;
        background-color: #F4F4F4;
        font-weight: bold;
        border-bottom: solid 1px #ccc;
    }

    .chaptercurrent {
        background-color: #FFFFD8;
        color: #000;

        .chapterstatus {
            background-image: url('../../images/toc-box-arrow.png');
        }
    }

    .chapternotstarted {
        color: #666;

        &:nth-child(even) {
            background-color: #efefef;
        }

        .chapterstatus {
            background-image: url('../../images/toc-box.png');
        }
    }

    .chapterstatus {
        vertical-align: top;
        @extend .p-1;
        text-align: center;
        background-position: center 4px;
        background-repeat: no-repeat;
    }

    .chaptercomplete .chapterstatus {
        background-image: url('../../images/toc-box-check.png');
    }

    .chapter {
        @extend .p-1;

        .title {
            @extend .mb-1;
            width: 100%;

            td {
                vertical-align: top;
            }
        }
    }

    .title {
        .titletext {
            font-weight: bold;
        }

        .expand {
            width: 80px;
            cursor: pointer;
            text-decoration: underline;
            color: #005C99;
            float: right;
            text-align: right;
            font-size: 11px;
        }
    }

    .chaptercomplete {
        .title .titletext {
            color: #005C99;
        }

        .lessons {
            display: none;
        }
    }

    .lessons {
        @extend .mt-1;

        ul {
            margin-bottom: 0;

            li {
                padding: 2px 0 2px 8px;
                margin: 0 0 0 10px;
                list-style-position: outside;

                &.lessonnotstarted {
                    font-weight: bold;
                    color: #828282;
                    list-style: none outside url("../../images/toc-bullet.png");
                }

                &.lessoncomplete {
                    list-style: none outside url("../../images/toc-check.png");
                }

                &.lessoncurrent {
                    list-style: none outside url("../../images/toc-arrow.png");
                }
            }
        }
    }
}


/* lessons*/
.page-lesson {
    .course-location {
        display: grid;
        position: sticky;
        top: 0px;
        grid-template-columns: 1fr 1fr;
        background: #fff;

        .course-title {
            padding-bottom: 0.5rem;
            font-size: $h4-font-size;
            @extend .fw-bold;
        }

        .course-time {
            text-align: right;
            padding-bottom: 0.5rem;
        }

        @include media-breakpoint-only(xs) {
            grid-template-columns: 1fr;

            .course-time {
                text-align: left;
            }
        }
    }
}

// This stuff works in multiple areas
#timeremaining {
    #counttimer {
        display: inline-block;
        padding: 2px 6px;
        background-color: #666;
        color: #fff;
        font-weight: bold;
    }

    #countdone {
        font-weight: bold;
    }
}

/* quizzes */
.question {
    @extend .mb-3;
    padding: 4px;
    border: solid 1px #fff;

    .questiontext {
        font-weight: bold;
        margin-bottom: 0.5em;
    }

    .missing {
        display: none;
    }

    &.input-validation-error {
        border: solid 1px #CD0000;
        border-radius: 8px;

        .missing {
            display: block;
            color: #CD0000;
            margin-bottom: 0.5em;
            font-weight: bold;
        }
    }
}

.answers {
    label {
        margin-left: 8px;
    }

    .answer {
        margin-top: 2px;
        margin-bottom: 2px;
    }
}

/* quiz results */

.quizresultitem {
    @extend .mb-2;
    @extend .pb-2;
    border-bottom: solid 1px #ccc;

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }

    .quizresultquestion {
        margin-bottom: 0.5em;
    }

    .answercorrect {
        color: #3c763d;
    }

    .answerincorrect {
        color: #843534;
    }
}


/* responsive youtube */
.videoyoutube {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;

    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}


.videoflashoutside {
}

.videoflash {
}

#vidplayerinside_logo {
    display: none !important;
}

.socialpanel {
    margin-top: 1em;
}

.sqtimeremaining {
    margin-bottom: 10px;
}
